import React from 'react'
import GoogleMapReact from 'google-map-react'
const GOOGLE_MAP_API_KEY = 'AIzaSyACOUeB9AQCAl6zsaKN6xJmZdqZL2XrcA4';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
const OurHistory = () => {
    return (
        <div style={{ height: '400px', width: '100%' }}>
        <GoogleMapReact
            bootstrapURLKeys={{
                key: GOOGLE_MAP_API_KEY
            }}
            defaultCenter={{
                lat: 34.683131,
                lng: 135.49864300000002
            }}
            defaultZoom={15}
        >
          <AnyReactComponent
              lat={34.683131}
              lng={135.49864300000002}>株式会社リタッチ</AnyReactComponent>
        </GoogleMapReact>
        </div>
    )
};

export default OurHistory