import React from 'react';
import Layout from '../components/App/Layout';
import Navbar from '../components/App/Navbar';
import PageBanner from '../components/Common/PageBanner';
import Footer from '../components/App/Footer';
import AboutUsContent from '../components/AboutUs/AboutUsContent';
import GoogleMap from '../components/AboutUs/GoolgeMap';
import TeamMembers from '../components/AboutUs/TeamMembers';
import SEO from '../components/App/SEO';

const AboutUs = () => {
    return (
        <Layout>
          <SEO
            title="会社情報"
            desc="リタッチの会社情報です。"
            pathname={'/about-us'}
            type={'Article'}
            shortName="会社情報"
            createdAt="2021-03-02"
            updatedAt="2021-03-02"
          />
            <Navbar />
            <PageBanner
                pageTitle="会社情報"
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="会社情報"
            />
            <AboutUsContent />
          {/*<TeamMembers />*/}
            <GoogleMap />
            <Footer />
        </Layout>
    );
};

export default AboutUs;