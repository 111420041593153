import React from 'react';
import aboutImage from '../../assets/images/about/about-img5.png';
import shape1 from '../../assets/images/shape/circle-shape1.png';
import Image from '../../components/UiComponent/image'
import {StaticImage} from 'gatsby-plugin-image';

const AboutUsContent = () => {
    return (
        <section className="about-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <StaticImage src="../../assets/images/about/about-img5.png" alt="banner" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                {/*<span className="sub-title">*/}
                                {/*    <img src={starIcon} alt="banner" /> */}
                                {/*    ビジョン*/}
                                {/*</span>*/}
                                {/*<h2>価値を繋げる、届ける</h2>*/}

                                <h3>会社名</h3>
                                <p>株式会社リタッチ</p>
                                <h3>設立</h3>
                                <p>2019年4月</p>
                                <h3>代表取締役</h3>
                                <p>福嶋英雄</p>
                                <h3>本社住所</h3>
                                <p>〒541-0053<br/>大阪府大阪市中央区本町4丁目4−17 RE-012 304</p>
                                <h3>事業内容</h3>
                                <p><ul>
                                    <li>
                                        自社サービスの運営
                                    </li>
                                    <li>ポータルサイトの企画・運用に関するコンサルティング業務</li>
                                    <li>広告代理業及び各種宣伝に関する業務</li>
                                </ul></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="circle-shape1">
                <StaticImage src={"../../assets/images/shape/circle-shape1.png"} alt="banner" />
            </div>
        </section>
    )
};

export default AboutUsContent;